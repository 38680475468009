import {Head,  usePage} from '@inertiajs/react';
import InputError from "@/Components/InputError";
import { pageBaseAddress } from '@/stores/GlobalData';
import { useWebApp } from "@vkruglikov/react-telegram-web-app";
import React, { useEffect, useState } from "react";
import trunkImage from '../../images/trunk.png';
import '../../css/main-game.css';
import '../../css/app.css';
import DOMPurify from 'dompurify';
import Loading from "@/Components/Game/GeneralComponents/Loading";
import qrcode from '../../images/qrcode.png';
import monkeyNo from '../../images/monkey-no.png';
import monkeyYes from '../../images/monkey-yes.png';
import { router } from '@inertiajs/react'


export default function Landing({ params,env }) {
    const { error } = usePage().props
    const [isWindowLoaded, setIsWindowLoaded] = useState(false);
    const [telegramLoaded, setTelegramLoaded] = useState(false);
    const [bgColor, setBgColor] = useState('#ffffff');
    const [textColor, setTextColor] = useState('#000000');
    const [user, setUser] = useState(null);
    const telegramWebApp = useWebApp();
    const [showDesktopError, setShowDesktopError] = useState(false);
    useEffect(() => {
        if (!telegramWebApp) return;
        setTelegramLoaded(true);
        setBgColor(telegramWebApp.backgroundColor);
        setTextColor(telegramWebApp.themeParams.text_color);
        setUser(telegramWebApp.initDataUnsafe.user);
    }, [telegramWebApp]);

    useEffect(() => {
        if (telegramLoaded && isWindowLoaded) {
            setTimeout(() => {
                  submit();
            }, 1000);
        }
    }, [telegramLoaded, isWindowLoaded]);
    useEffect(() => {
        const handleLoad = () => {
            setIsWindowLoaded(true);
        };

        if (document.readyState === 'complete') {
            setIsWindowLoaded(true);
        } else {
            window.addEventListener('load', handleLoad);
        }

        return () => {
            window.removeEventListener('load', handleLoad);
        };
    }, []);
    const submit = () => {
        //@ts-ignore
        gtag("event", 'page_view', { 'page_location': pageBaseAddress + 'register', 'page_title': 'register' });
        const data={
            initData: telegramWebApp.initData,
            initDataUnsafe: JSON.stringify(telegramWebApp.initDataUnsafe),
            tz:Intl.DateTimeFormat().resolvedOptions().timeZone ,
            params: params,
        }

        if (telegramWebApp) {
            if(telegramWebApp.platform==='tdesktop' && env==='production2'){
                setShowDesktopError(true);
            }else {

                router.post(route('telegram.register'),data)
            }

        }
    };

    return (
        <div className="min-h-screen flex flex-col sm:justify-center items-center pt-6 sm:pt-0" style={{ backgroundColor: bgColor }}>
            <div className="max-w-md min-w-[300px]  rounded-lg mx-[5px]">
                <Head>
                    <title>Chroma cubs</title>
                </Head>
                {!showDesktopError ?
                    <React.Fragment>
                <div className={'w-[1px] h-[1px]'}>
                    <div className={'gp-branch-color-sign'} />
                    <div key='trunk' className="trunk absolute w-0 h-0 right-0 top-0 bg-repeat-y z-[2]"
                         style={{ backgroundImage: `url(${trunkImage})` }}></div>
                </div>
                        <div className="flex items-center justify-center mt-4 flex-col" style={{color: textColor}}>
                            <div className={'gp-login w-[219px] h-[219px] mb-[20px]'}/>
                            {(telegramLoaded && user) && <div> Welcome, {DOMPurify.sanitize(user.first_name)}</div>}
                            <div style={{color: textColor}}>We are loading your forrest...</div>
                            {!error &&
                                <div className={'mt-[20px]'}>
                                    <Loading/>
                                </div>
                            }
                            {!isWindowLoaded && <div style={{color: textColor}} className={'text-1'}>Loading assets</div>}
                            {!telegramLoaded &&<div style={{color: textColor}} className={'text-1'}>Loading Telegram</div>}
                            <div className="flex items-center justify-center mt-4">
                                <InputError message={error} className="mt-2"/>
                            </div>

                        </div>
                    </React.Fragment>
                    :
                    <div className={'flex flex-col gap-y-3  rounded-lg pb-3 bg-yellow-100'}>
                    <div  className={'p-3 text-black centered px-5 mt-3'}>
                            <div className={'icon-warning w-[20px] h-[20px] mx-auto mr-1'}/>
                            <div>Chroma Cubs is only available on mobile!</div>

                            </div>
                        <div className={'centered '}>
                            <div>
                                <img src={monkeyNo} width={100} height={100} alt={'An upset monkey'}/>

                            </div>
                            <div className={' '}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5} stroke="currentColor" className="size-10">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25"/>
                                </svg>
                            </div>
                        </div>
                        <div className={'centered'}>
                            <div>
                                <img src={monkeyYes} width={100} height={100} alt={'A happy monkey'}/>

                            </div>
                            <div className={' '}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5} stroke="currentColor" className="size-10">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"/>
                                </svg>
                            </div>
                        </div>
                        <div className={'centered'}>
                            <img src={qrcode} width={200} height={200} alt={'QR Code'}/>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

